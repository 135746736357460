<template>
    <v-app>
       <v-main>
          <v-container fluid fill-height class="hero">
             <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4 >
                   <v-card class="elevation-12"  style="border-radius:30px 30px 30px 30px" color="#E0E0E0">
                      <v-toolbar dark color="#263238">
                         <v-toolbar-title >CITAV | Consultores
                           <!-- {{isRegister ? stateObj.register.name : stateObj.login.name}}  -->
                         </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                      <form ref="form" @submit.prevent="isRegister ? register() : login()">
                             <v-text-field
                               v-model="username"
                               name="username"
                               label="Usuario"
                               type="text"
                               placeholder="Usuario"
                               required
                            ></v-text-field>
                            
                             <v-text-field
                               v-model="password"
                               name="password"
                               label="Contraseña"
                               type="password"
                               placeholder="Contraseña"
                               required
                            ></v-text-field>
 
                            <v-text-field v-if="isRegister"
                               v-model="confirmPassword"
                               name="confirmPassword"
                               label="Confirmar Contraseña"
                               type="password"
                               placeholder="Confirmar Contraseña"
                               required
                            ></v-text-field>
                            <div class="red--text"> {{errorMessage}}</div>
                            <v-btn type="submit" class="mt-4" color="#F57C00" style="color:white" value="log in">{{isRegister ? stateObj.register.name : stateObj.login.name}}</v-btn>
                            <div class="grey--text mt-4" v-on:click="isRegister = !isRegister;">
                               {{toggleMessage}}  
                            </div>
                       </form>
                      </v-card-text>
                   </v-card>
                 
                </v-flex>
             </v-layout>
          </v-container>
       </v-main>
    </v-app>
 </template>
 <style>
.hero {
   background: url('../assets/CarrosUsados.jpg');
   background-size: cover;
   height: 100vh;
 }

 #logo {
   background-image: url('../assets/LogoCitav.png');
   background-repeat: no-repeat;
   background-size:contain; 
   align-content: center;
 }
</style>
 
 <script>
import axios from 'axios';
 export default {
   name: "App",
   data() {
     return {
       username: "",
       password: "",
       confirmPassword: "",
       isRegister : false,
       errorMessage: "",
       stateObj: {
          register :{
             name: 'Registrarse',
             message: 'Ya tiene una cuenta? De click aqui para Iniciar Sesion.'
          },
          login : {
             name: 'Iniciar Sesion',
             message: 'Registrarse'
          }
       }
     };
   },
   methods: {
     login() {
      
      let datos={
             username:this.username,
             password:this.password
            };
       axios.post("https://ibingcode.com/public/login", datos).then((result) => {
    if (result.data.status==true){
      this.$router.push({ name: "dashboard", params: {username:result.data.nombre} });
      localStorage.setItem('nombre', result.data.nombre);
      localStorage.setItem('token', result.data.token);

    }else{
      alert('Datos Errados, intente nuevamente');
      
    }
  });
       
     },
     register() {
        if(this.password == this.confirmPassword){
           this.isRegister = false;
           this.errorMessage = "";
           this.$refs.form.reset();
        }
        else {
          this.errorMessage = "password did not match"
        }
     }
   },
       computed: {
        toggleMessage : function() { 
           return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message }
     },
     
  
     
 };
 </script>