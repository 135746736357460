import Vue from 'vue'
import App from './App.vue'
import router from './routes/router'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import interceptorsSetup from './routes/interceptores';
interceptorsSetup()


const options = {
  // confirmButtonColor: '#41b882',
  confirmButtonColor: '#F57C00',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2,options);



Vue.config.productionTip = false


new Vue({
 router,
  vuetify,
  iconfont: 'md',

  render: h => h(App)
}).$mount('#app')
