<template>
  <div>
    <v-toolbar flat color="#263238" dark fixed>
      <v-toolbar-title>INSPECCIONES</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      
     
      <v-btn v-show="components_5" style="color:rgb(247, 237, 237)" color="#F57C00" dark class="mr-1 mb-2" @click="ExportarExcel()"><v-icon medium>file_download</v-icon></v-btn>
      <b></b><b></b><b></b><b></b>
      <v-btn v-show="components_6" style="color:rgb(247, 237, 237)" color="#F57C00" dark class="mb-2"><v-icon medium @click="condition1 = true;condition3=false;condition4=false;components_5=false;components_6=false;accion=2">reply</v-icon></v-btn>  
        
    </v-toolbar>
    
    <v-card v-show="condition1">
      <v-card-title>
        <span class="headline"></span>
      </v-card-title>

      <v-card-text>
        
        <v-container grid-list-md>
          <v-layout wrap >
            <v-flex xs12 sm6 md4>
            <v-radio-group inline label="Tipo de busqueda">
              <v-radio label="Placa"  value="placa"  @click="condition2=true;accion=1"></v-radio>
              <v-radio label="Cosulta Por Fecha" value="fecha"  @click="condition2=false;accion=2"></v-radio>
            </v-radio-group>
          </v-flex>


          </v-layout>
          <v-layout wrap v-show="condition2">
            
            <v-flex xs12 sm6 md4>
              <v-text-field v-model="placa" label="Placa"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout wrap v-show="!condition2">
            <v-flex xs12 sm6 md4>
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date" label="Fecha_Inicio_Inspeccion" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="menu = false" color="orange" header-color="orange"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date2" label="Fecha_Final_Inspeccion" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date2" @input="menu2 = false" color="orange"
                  header-color="orange"></v-date-picker>
              </v-menu>
            </v-flex>          

          </v-layout>
          
        </v-container>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn style="color:rgb(247, 237, 237)" color="#F57C00" dark
          class="mb-2" @click="BuscarInspeccion();components_5=true;components_6=true" >Buscar</v-btn>

      </v-card-actions>

    </v-card>
    <v-divider  color="info"></v-divider>

    <v-card v-show="condition3">
      <v-card-title>
        <span class="headline">
          <h6>STATUS INSPECCION</h6>
        </span>
      </v-card-title>
        <div class="text-center"  v-if="estado_inspeccion = 0 ">
        <v-chip class="ma-2" large color="#F57C00"><h4>RUNT</h4></v-chip>
        <v-chip class="ma-2" large><h4>INVENTARIO</h4></v-chip>
        <v-chip class="ma-2" large><h4>PUBLICACION</h4></v-chip>
        <v-chip class="ma-2" large><h4>ABANDONO</h4></v-chip>
        <v-chip class="ma-2" large><h4>PERITAJE</h4></v-chip>
    </div>
    <div class="text-center"  v-if="estado_inspeccion=1">
        <v-chip class="ma-2" large  color="#F57C00"><h4>RUNT</h4></v-chip>
        <v-chip class="ma-2" large color="#F57C00"><h4>INVENTARIO</h4></v-chip>
        <v-chip class="ma-2" large><h4>PUBLICACION</h4></v-chip>
        <v-chip class="ma-2" large><h4>ABANDONO</h4></v-chip>
        <v-chip class="ma-2" large><h4>PERITAJE</h4></v-chip>
    </div>
    <div class="text-center"  v-else-if="estado_inspeccion=2">
        <v-chip class="ma-2"><h4>RUNT</h4></v-chip>
        <v-chip class="ma-2"><h4>INVENTARIO</h4></v-chip>
        <v-chip class="ma-2" color="#F57C00"><h4>PUBLICACION</h4></v-chip>
        <v-chip class="ma-2"><h4>ABANDONO</h4></v-chip>
        <v-chip class="ma-2"><h4>PERITAJE</h4></v-chip>
    </div>
    <div class="text-center"  v-else-if="estado_inspeccion=3">
        <v-chip class="ma-2"><h4>RUNT</h4></v-chip>
        <v-chip class="ma-2"><h4>INVENTARIO</h4></v-chip>
        <v-chip class="ma-2"><h4>PUBLICACION</h4></v-chip>
        <v-chip class="ma-2"  color="#F57C00"><h4>ABANDONO</h4></v-chip>
        <v-chip class="ma-2"><h4>PERITAJE</h4></v-chip>
    </div>
    <div class="text-center"  v-else>
        <v-chip class="ma-2"><h4>RUNT</h4></v-chip>
        <v-chip class="ma-2"><h4>INVENTARIO</h4></v-chip>
        <v-chip class="ma-2"><h4>PUBLICACION</h4></v-chip>
        <v-chip class="ma-2"><h4>ABANDONO</h4></v-chip>
        <v-chip class="ma-2" color="#F57C00"><h4>PERITAJE</h4></v-chip>
    </div>
      <v-card-title>
        <span class="headline">
          <h6>INFORMACION VEHICULO</h6>
        </span>
      </v-card-title>

      <v-card-text>

        <v-data-table :headers="headers_vehiculo" :items="vehiculo" class="elevation-1">
          <template  v-slot:[`item.actions`]="{item}" >
      
      <v-icon @click="DescargarImagenes(item)">mdi-download</v-icon>
    
      </template>   


        </v-data-table>
      </v-card-text>
      <v-spacer></v-spacer>
      
      <v-card-title>
        <span class="headline">
          <h6>INFORMACION PROPIETARIO</h6>
        </span>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers_propietario" :items="propietario" class="elevation-1">


        </v-data-table>
      </v-card-text>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>


            <v-flex v-for="(ruta) in rutas" v-bind:key="ruta.ruta" xs12 sm6 md4>
              <v-spacer></v-spacer>
              
              <H4 style="text-align: center;"> {{(ruta.ruta.substring(2,33)).replace(/.jpg/,"")}}</H4>
              <v-img :src="'https://soportescitav.ibingcode.com/' + ruta.ruta"></v-img>
            
              <v-spacer></v-spacer>
            </v-flex>

              
          </v-layout>
        
        </v-container>

      </v-card-text>

    </v-card>
  <v-card v-show="condition4">
    <v-card-text>

<v-data-table :headers="headers_vehiculo_main" :items="vehiculo" @click:row="DetalleInspeccion" class="elevation-1">
  
</v-data-table>
</v-card-text>
  </v-card>




  </div>
</template>
<script>
import axios from 'axios';
export default {
  data: () => ({
    dialog: false,
    headers_vehiculo_main: [
    

      { text: '#INSPECCION', value: 'id_inspeccion' },
      { text: 'FECHA_INICIO', value: 'fecha_inspeccion' },
      { text: 'PROYECTO', value: 'nombre_proyecto' },
      { text: 'PLACA', value: 'placa' },
      { text: 'TIPO_VEHICULO', value: 'tipo_vehiculo' },
      { text: 'ESTADO', value: 'estado' },
      { text: 'FUNCIONARIO', value: 'nombre_funcionario' }
     
    ],
    headers_vehiculo: [
    

      { text: '#INSPECCION', value: 'id_inspeccion' },
      { text: 'FECHA_INICIO', value: 'fecha_inspeccion' },
      { text: 'PROYECTO', value: 'proyecto' },
      { text: 'PLACA', value: 'placa' },
      { text: 'TIPO_VEHICULO', value: 'tipo_vehiculo' },
      { text: 'ESTADO', value: 'estado' },
      { text: 'DESCARGAR IMAGENES', value: 'actions'}

    ],
    estado_inspeccion:"",
    headers_propietario: [


      { text: 'PROPIETARIO', value: 'id_propietario' },
      { text: 'FECHA_INICIO', value: 'fecha_inspeccion' },
      { text: 'PROYECTO', value: 'proyecto' }

    ],

    vehiculo: [],
    propietario: [],
    rutas: [],
    
    condition1: true,
    condition2: true,
    condition3:false,
    condition4:false,
    components_5:false,
    components_6:false,
    placa: "",
    fecha_inspeccion: "",    


    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    codigo_inspeccion:"",
    option_search: [{
            id: 0,
            name: "fecha"
          },
          {
            id: 1,
            name: "placa"
          },
          {
            id: 2,
            name: "0:1"
          },
          {
            id: 3,
            name: "1:0 B"
          }],
          ex4: true,

  }),

  methods: { 
    
    

    DetalleInspeccion(item){     
             
      this.condition1=false
      this.condition3=true
      this.condition4=false
      this.vehiculo = []
      console.log(item.placa);
      let datos={
      placa: item.placa

      };

    axios.post("https://ibingcode.com/public/infovehiculo",datos).then((result) => {
    console.log(this.vehiculo = result.data);
    console.log(this.estado_inspeccion = result.data.estado);
    console.log(this.propietario = result.data);
    this.codigo_inspeccion=result.data[0].id_inspeccion;
    console.log(this.codigo_inspeccion);
      let datos = {
      codigo_inspeccion:(this.codigo_inspeccion)
      };


     axios.post("https://ibingcode.com/public/getsoporte",datos).then((result) => {
    console.log(this.rutas = result.data);


       });

      });
  
    },

    DescargarImagenes(item){   
      
      window.open('https://ibingcode.com/descargas.php?dato='+item.id_inspeccion, '_blank');

   },

   ExportarExcel(){     

      window.open('https://ibingcode.com/descarga_excel.php?fecha_inicio='+this.date+'&fecha_fin='+this.date2, '_blank');

  },


    ListarInspecciones() {
  
      console.log(this.accion)
      if (this.accion==1){

        let datos={
        placa: this.placa

      };
       axios.post("https://ibingcode.com/public/infovehiculo",datos).then((result) => {
      console.log(this.vehiculo = result.data);
      console.log(this.estado_inspeccion = result.data.estado);
      console.log(this.propietario = result.data);
      this.codigo_inspeccion=result.data[0].id_inspeccion;
      console.log(this.codigo_inspeccion);
      
      let datos = {
      codigo_inspeccion:(this.codigo_inspeccion)
      };


      axios.post("https://ibingcode.com/public/getsoporte",datos).then((result) => {
      console.log(this.rutas = result.data);


      });

     });

      }else if(this.accion==2){

        this.condition1=false
        this.condition3=false
        this.condition4=true

        let datos = {
         fecha_inicio: (this.date),
         fecha_fin: (this.date2)
         
      };
      
      this.vehiculo = [];    


      axios.post("https://ibingcode.com/public/pro_listarInspecciones", datos).then((result) => {
          this.vehiculo = result.data == "" ? [] : result.data;
      
      });

      }
      
    },
    BuscarInspeccion() {
      console.log(this.accion)
      if (this.accion==1) {
        
    
        this.condition1=false
        this.condition3=true
        this.condition4=false
        
        this.v_placa = this.placa;
        this.ListarInspecciones();
        
      }else if(this.accion==2){ 
        console.log()
        
        
        this.ListarInspecciones();
      }else if(this.accion==3){ 
        
        
        
        this.ListarInspecciones();
      }
   
    }


  }
}
</script>