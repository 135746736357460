<template>
    <div>
      <v-toolbar flat color="#263238" dark fixed>
        <v-toolbar-title>VEHICULOS</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>       
      </v-toolbar>
      <v-data-table
      :headers="headers"
      :items="vehiculos" 
      class="elevation-1"
      >    

  
    </v-data-table>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data: () => ({
      dialog: false,
      headers: [
       
        { text: 'PLACA',value: 'placa' },        
        { text: 'MODELO',value:'v.modelo' },
        { text: '#CHASIS',value:'numero_chasis' },
        { text: 'MARCA',value:'marca'},
        { text: 'TIPO_VEHICULO',value:'tipo_vehiculo'},     
        { text: 'ORGANISMO_TRANSITO',value:'organismo_transito'}
       
        
        
      
      ],

      clientes:[],
      files:[],
      file:'',
      vehiculos:[],
      editedIndex: -1,
      accion:'N',
      editedItem: {
      idciudad: '',
      nombre: ''
      
    },
    username: "",
       password: "",
       confirmPassword: "",
       isRegister : false,
       errorMessage: "",

       date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
       date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    
    }),
created(){
this.ObtenerDatosVehiculo();

},
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Proyecto' : 'Editar Proyecto'
      }
    },
    
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },  

    methods: {
       
      
      

      ObtenerDatosVehiculo() {
        let datos={
          placa:'MHY778'
             
            };
        axios.post("https://ibingcode.com/public/infovehiculo",datos).then((result) => {
        this.vehiculos=result.data;
   
  });
      }
        
        
    }
  }
</script>