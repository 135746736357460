<template>
    <div>
      <v-toolbar flat color="#263238" dark fixed>
      <v-toolbar-title>DOCUMENTOS_TECNICOS</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      
      <v-btn v-show="components_2" style="color:rgb(247, 237, 237)" color="#F57C00" dark class="mb-2"><v-icon medium @click="components_1=true;components_2=false;components_3=false">backspace</v-icon></v-btn>
    </v-toolbar>
    <v-card v-show="components_1">
    <v-card-text>
    <v-data-table
      :headers="headers_documentos"
      :items="documentos" 
      class="elevation-1"
      >
      <template  v-slot:[`item.accion`]="{item}"
      
      >
      
      <v-icon @click="f_detalledocumento(item);components_1=false;components_2=true;components_3=true">mdi-eye</v-icon>
      
      </template>    

  
    </v-data-table>
  </v-card-text>
  </v-card> 
       
          <v-card v-show="components_3">
            <v-card-title>
              <span class="headline">{{formTitle}}</span>
            </v-card-title>
  
            <v-card-text>
              <v-container grid-list-md>
                <v-card-title>
                  <span class="headline">{{nombre_documento.toUpperCase()}}</span>
                </v-card-title>
                <v-layout wrap v-for="datos in detalledocumento" v-bind:key="datos.id_documento">
                  
                  <v-flex  
                  
                  xs12 sm6 md4>
                    <v-textarea v-model="datos.texto_1"   outlined
            name="input-7-4"  label="texto_1"></v-textarea>
                </v-flex>
                  <v-flex   xs12 sm6 md4>
                    <v-textarea  v-model="datos.texto_2"   outlined
            name="input-7-4" label="texto_2"></v-textarea>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-textarea v-model="datos.texto_3"
                    outlined
            name="input-7-4"
            
                     label="texto_3"></v-textarea>
                  </v-flex>

                  <v-flex xs12 sm6 md4>
                    <v-textarea v-model="datos.texto_4"
                    outlined
            name="input-7-4"
            
                     label="texto_4"></v-textarea>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-textarea v-model="datos.texto_5"
                    outlined
            name="input-7-4"
            
                     label="texto_5"></v-textarea>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-textarea v-model="datos.texto_6"
                    outlined
            name="input-7-4"
            
                     label="texto_6"></v-textarea>
                  </v-flex>          
            
                              

                              
                </v-layout>
              </v-container>
            </v-card-text>
  
            <v-card-actions>
                <v-spacer></v-spacer>
                
                

                <v-btn  class="mt-4" color="#F57C00" style="color:white" @click="actualizar_documento" >Guardar</v-btn>
  
  
              </v-card-actions>
          </v-card>
        
    
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data: () => ({
      
      headers_documentos: [
      
  
        { text: '#DOCUMENTO', value: 'id_documento' },
        { text: 'NOMBRE', value: 'documento' },
        { text: 'ACCION', value: 'accion' }        
  
  
  
      ],
      detalledocumento:[],
      documentos:[],
      id_documento:'',
      nombre_documento:'',   
      components_1:true,
      components_2:false,
      components_3:false,
  
    }),
    created(){
this.f_listardocumento();
},
  
  
    methods: {

    f_listardocumento(){ 
      
          axios.get("https://ibingcode.com/public/listardocumentos").then((result) => {
          console.log(this.documentos=result.data); 
          // console.log("dssds");
          // console.log(result);
          });

    },
    f_detalledocumento(item){
   
          let datos = {
            id_documento:(item.id_documento)
          };

          axios.post("https://ibingcode.com/public/detalledocumentos",datos).then((result) => {
          console.log(this.detalledocumento = result.data);
          this.nombre_documento=this.detalledocumento[0]['documento'];
          
          });

    },
        
    actualizar_documento () {
      
          let datos={
             id_variable:this.editedItem.id_variable,
             texto:this.editedItem.texto             
          };

          axios.post("https://ibingcode.com/public/updatedocumento", datos).then((result) => {
          let resp = JSON.parse(result.data);
          this.$swal.fire(resp.CODIGO == 0 ? 'EXITOSO' : 'ERROR', resp.DATOS, resp.CODIGO == 0 ? 'success' : 'error')
          });
     } 
  
    }
  }
  </script>