import Vue from 'vue'
import VueRouter from 'vue-router'
import ModuloMain from '../components/ModuloMain.vue'
import Login from '../components/Login.vue'
import ListarCiudad from '../components/ListarCiudad.vue'
import CrearFuncionario from '../components/CrearFuncionario'
import CrearCliente from '../components/CrearCliente'
import CargarLista from '../components/CargarLista'
import DashBoard from '../components/DashBoard.vue'
import ProyEcto from '../components/ProyEcto.vue'
import CliEnte from '../components/CliEnte.vue'
import InspeCcion from '../components/InspeCcion.vue'
import VehIculos from '../components/VehIculo.vue'
import DocuMentos from '../components/DocuMentos.vue'

Vue.use(VueRouter)

  
  const routes= [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/modulo',
      name:'modulo',
      component: ModuloMain,
      children:[{
        path: '/dashboard',
        name: 'dashboard',
        component: DashBoard,
        props: {},
        meta:{requiresAuth:true}
      },
      {
        path: '/listarciudad',
        name: 'listarciudad',
        component: ListarCiudad,
        props: {},
        meta:{requiresAuth:true}
      },
      {
        path: '/crearfuncionario',
        name: 'crearfuncionario',
        component: CrearFuncionario,
        props: {},
        meta:{requiresAuth:true}
      }
      ,
      {
        path: '/crearcliente',
        name: 'crearcliente',
        component: CrearCliente,
        props: {},
        meta:{requiresAuth:true}
      }
      ,
      {
        path: '/cargarlista',
        name: 'cargarlista',
        component: CargarLista,
        props: {},
        meta:{requiresAuth:true}
      }
      ,
      {
        path: '/proyectos',
        name: 'proyectos',
        component: ProyEcto,
        props: {},
        meta:{requiresAuth:true}
      }
      ,
      {
        path: '/listarcliente',
        name: 'listarcliente',
        component: CliEnte,
        props: {},
        meta:{requiresAuth:true}
      }
      ,
      {
        path: '/inspecciones',
        name: 'inspecciones',
        component: InspeCcion,
        props: {},
        meta:{requiresAuth:true}
      }
      ,
      {
        path: '/infovehiculo',
        name: 'infovehiculo',
        component: VehIculos,
        props: {},
        meta:{requiresAuth:true}
      }
      ,
      {
        path: '/listardocumento',
        name: 'listardocumento',
        component: DocuMentos,
        props: {},
        meta:{requiresAuth:true}
      }
    ]
    },
    
  ];
  const router = new VueRouter({
    mode: '',
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      const token = localStorage.getItem('token');
      if (token) {
        next();
      } else {
        next('/');
      }
    } else {
      next();
    }
  });
  export default router;
  
