<template>
  
  <v-toolbar flat color="#263238" dark fixed>
      <v-toolbar-title>DASHBOARD</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      
   
    </v-toolbar>
    </template>
    
    <script>

        export default {
   
        data () {
          return {
     
            
          }
        },
        methods: {
        

          listaciudad() {
            
          }
         }
    //,
    //     watch: {
    //   group () {
    //     this.drawer = false
    //   },
    // },
      }

    </script>
    