<template>
    <div>
      <v-toolbar flat color="#263238" dark fixed>
        <v-toolbar-title>PROYECTO</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn v-show="condition2" style="color:rgb(247, 237, 237)" color="#F57C00" dark class="mb-2" @click="condition1 = true;condition2 = false;condition3 = true;condition4 = false;condition5 = true"><v-icon medium >backspace</v-icon></v-btn>
        
            <v-btn v-show="condition3" style="color:rgb(247, 237, 237)" @click="dialog='true'" color="#F57C00" dark class="mb-2"><v-icon>add_circle</v-icon></v-btn>
            

         
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="800px">
          
          <v-card>
            <v-card-title>
              <span class="headline">{{formTitle}}</span>
            </v-card-title>
  
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                   <v-flex xs12 sm6 md4>
                    <v-text-field label="Nombre Proyecto"></v-text-field>
                  </v-flex>
                  

                  <v-flex xs12 sm6 md4>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Fecha_Inicio"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="menu = false"
          color="orange"
      header-color="orange"
        ></v-date-picker>
      </v-menu>
    </v-flex>

    <v-spacer></v-spacer>

                 
  
                  
    
      <v-flex xs12 sm6 md4>
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date2"
            label="Fecha_Final_Plan"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date2"
          @input="menu2 = false"
          color="orange"
      header-color="orange"
        ></v-date-picker>
      </v-menu>
    </v-flex>

    <v-spacer></v-spacer>
  

                  
                  

            <v-flex xs12 sm6 md4>
            <template>
            <div>
            <v-select
            

            :items="clientes"
            density="Cliente"
            label="Cliente"
            item-text="razon_social"
            item-value="idcliente"
            ></v-select>
           </div>
           </template>
                  </v-flex>

                  <v-flex xs12 sm6 md4>

                  <v-file-input
      v-model="files"
      placeholder="Cargar Lista"
      label="File input"
      multiple
      prepend-icon="mdi-paperclip"
    >
      <!-- <template v-slot:selection="{ fileNames }">
        <template v-for="fileName in fileNames" >
          <v-chip
          :key="fileName"
            size="small"
            label
            color="primary"
            class="me-2"
          >
            {{ fileName }}
          </v-chip>
        </template>
      </template> -->
    </v-file-input>
  </v-flex>

                              
                </v-layout>
              </v-container>
            </v-card-text>
  
            <v-card-actions>
                <v-spacer></v-spacer>                
                <v-btn style="color:rgb(247, 237, 237)" @click="close" color="#F57C00" dark class="mb-2" >Cancelar</v-btn>
                <v-btn style="color:rgb(247, 237, 237)" @click="save" color="#F57C00" dark class="mb-2" >Guardar</v-btn>
                <v-btn style="color:rgb(247, 237, 237)" @click="cargarlista" color="#F57C00" dark class="mb-2" >Cargar Lista</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-card>
      <v-divider
          class="mx-2"
          inset
          vertical
        ></v-divider>
        
      
        <div v-show="condition5" class="text-right">
          <span style="font-size:12px">Acta Inspeccion</span><v-icon>fact_check</v-icon>
          <span style="font-size:12px">Notificacion Personal</span><v-icon color="#263238"  >task</v-icon>
          <span style="font-size:12px">Notificacion Transito</span><v-icon  color="#F57C00">description</v-icon>
          <span style="font-size:12px">Resolucion Abandono</span><v-icon color="black">receipt_long</v-icon>
        </div>
  
    <v-divider
          
          
          vertical
        ></v-divider>

        
        <v-card v-show="condition1">
        <v-data-table
      :headers="headers"
      :items="proyectos" 
      class="elevation-1"
      >
      <template  v-slot:[`item.actions`]="{item}"  
      
      >
      <!-- <v-btn color="#F57C00" fab small dark> -->
       
      <v-icon @click="f_generardocumento(item.idproyecto,1)" >fact_check</v-icon>
      <v-icon @click="f_generardocumento(item.idproyecto,2)" color="#263238">task</v-icon>
      <v-icon @click="f_generardocumento(item.idproyecto,3)" color="#F57C00">description</v-icon>
      <v-icon @click="f_generardocumento(item.idproyecto,4)" color="black">receipt_long</v-icon>
      <!-- </v-btn> -->
    </template>  
      <template  v-slot:[`item.actions_1`]="{item}"  
      
      >
      
      <v-icon @click="condition1=false;condition2=true;condition3=false;condition4=true;condition5=false;ListarInspeccionProyecto(item)">mdi-eye</v-icon>
      
      </template>      
       
    </v-data-table>
  </v-card>

  <v-container fluid v-show="condition4">
    <v-card>

      <v-card-title>{{parametro_nombre}}

      </v-card-title>
    <v-row dense>
      
      
      <v-col v-for ="item in inspeccionproyecto" :key="item.idproyecto"   
          >
  <v-card
      
      class="mx-auto my-12"
      max-width="374"      
    >    
  
      <v-img
        height="200"
        src="../assets/CarrosUsados.jpg"
      ></v-img>
  
      <v-card-subtitle>{{item.ESTADO_VEHICULO}}</v-card-subtitle>
  
      <v-card-text>
        <v-row
          align="center"
          class="mx-0"
        >                    
        </v-row>          
      </v-card-text>
  
      <v-divider class="mx-4"></v-divider>
    

  <v-card-text>
    
    <v-chip-group
      
      active-class="deep-purple accent-4 white--text"
      column
      
    >
      <v-chip>10.000</v-chip>

      <v-chip>{{item.TOTAL}}</v-chip>

      <v-chip>{{ (item.TOTAL/10000)*100 }} %</v-chip>

      
    </v-chip-group>
  </v-card-text>
  <v-icon color="#F57C00" size="50" @click="f_descarga_soporte(item.IDPROYECTO,1)">mdi-email</v-icon>
    </v-card>
    </v-col>

    <v-col >
    <v-card
      
      class="mx-auto my-12"
      max-width="374"
    >
       
      <v-img
        height="200"
        src="../assets/CarrosUsados.jpg"
      ></v-img>
  
      <v-card-subtitle>ABANDONO</v-card-subtitle>
  
      <v-card-text>
        <v-row
          align="center"
          class="mx-0"
        >
         
  
          
        </v-row>
  
        
      </v-card-text>
  
      <v-divider class="mx-4"></v-divider>
  
  

  <v-card-text>
    <v-chip-group
      
      active-class="deep-purple accent-4 white--text"
      column
    >
      <v-chip>10.000</v-chip>

      <v-chip>0</v-chip>

      <v-chip>0%</v-chip>

      
    </v-chip-group>
  </v-card-text>
  
     
  <v-icon color="#F57C00" size="50">mdi-email</v-icon>
     
    </v-card>
  </v-col>
    <v-col 
           
          >
    <v-card
      
      class="mx-auto my-12"
      max-width="374"
    >
       
      <v-img
        height="200"
        src="../assets/CarrosUsados.jpg"
      ></v-img>
  
      <v-card-subtitle>AVALUOS</v-card-subtitle>
  
      <v-card-text>
        <v-row
          align="center"
          class="mx-0"
        >
         
  
          
        </v-row>
  
        
      </v-card-text>
  
      <v-divider class="mx-4"></v-divider>
  
  

  <v-card-text>
    <v-chip-group
      
      active-class="deep-purple accent-4 white--text"
      column
    >
      <v-chip>10.000</v-chip>

      <v-chip>0</v-chip>

      <v-chip>0%</v-chip>

      
    </v-chip-group>
  </v-card-text> 
     
      
  <v-icon color="#F57C00" size="50">mdi-email</v-icon>
     
    </v-card>
  </v-col>
  
  </v-row>
</v-card>
  </v-container>
</v-card>
    </div>
  </template>
  <script>
  import axios from 'axios';
  //import jsPDF from "jspdf";

  export default {
    data: () => ({
      dialog: false,
      headers: [
       
        { text: '#PROYECTO',value: 'idproyecto' },
        { text: 'NOMBRE',value:'nombre'},
        { text: 'FECHA_INICIO',value:'fecha_inicio' },
        { text: 'FECHA_FIN_PLAN',value:'fecha_final_plan' },
        { text: 'CLIENTE',value:'razon_social'},
        { text: 'SECRETARIA',value:'secretaria'},
        { text: 'DOCUMENTOS',value:'actions'},
        { text: 'DETALLE',value:'actions_1'}
        // { text: 'NOTIFICACION TRANSITO',value:'actions'},
        // { text: 'NOTIFICACION PERSONAL',value:'actions'},
        // { text: 'RESOLUCION DECLARATORIA',value:'actions'}
        
        
      
      ],

      clientes:[],
      inspeccionproyecto:[],
      files:[],
      file:'',
      proyectos:[],
      editedIndex: -1,
      accion:'N',
      editedItem: {
      direccion: '',
      fecha_final_plan:'',
      fecha_inicio:'',
      idproyecto:'',
      nombre: '',
      razon_social:'',
      secretaria:''
      
    },
   
       password: "",
       confirmPassword: "",
       isRegister : false,
       errorMessage: "",

       date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
       date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      respuesta:'',
      tipo_reporte:'',
      condition1: true,
      condition2: false,
      condition3: true,
      condition4:false,
      condition5:true,
      parametro_nombre:"",
      title1:''
    
    }),
created(){
this.ListarProyecto();
this.ListarCliente();
},
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Proyecto' : 'Editar Proyecto'
      }
      
    },
    
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },  

    methods: {
      ListarInspeccionProyecto(item) {
        this.$swal.fire({
                title: 'Por Favor Espere!',
                html: 'Cargando Datos',// add html attribute if you want or remove
                allowOutsideClick: false,
                showConfirmButton:false,
                

                willOpen: () => {                  
                  
                  this.$swal.showLoading()
                  
                },
            });

        this.parametro_nombre=item.nombre;

        this.inspeccionproyecto=[];

       
        axios.post("https://ibingcode.com/public/listarinspeccionproyecto",{idproyecto:item.idproyecto}).then((result) => {
        
      
            console.log(this.inspeccionproyecto=result.data);

            this.$swal.close();

      });
      
    },
      ListarProyecto() {
        axios.get("https://ibingcode.com/public/listarproyecto").then((result) => {
        this.proyectos=result.data;
   
  });
      },
      
      ListarCliente() {
        axios.get("https://ibingcode.com/public/listarcliente").then((result) => {
        this.clientes=result.data;
   
  });
      }, 


    //   editItem (item) {
        
      
     
      
   
      
    //  },
      close () {
      this.dialog = false
      this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      })
    },
    save () {
      
      let datos={             
             nombre:this.editedItem.nombre,
             accion:this.accion
            };
       axios.post("https://ibingcode.com/public/save", datos).then((result) => {
        let resp = JSON.parse(result.data);
      this.$swal.fire(resp.CODIGO == 0 ? 'EXITOSO' : 'ERROR', resp.DATOS, resp.CODIGO == 0 ? 'success' : 'error')
  });
    },
    cargarlista() {
      if (this.files=='') {
        this.$swal.fire(
          "Intentar de nuevo",
          "No Adjunto el archivo",
          "info"
        );
      } else {
        // this.$swal.fire({
        //   title: "Cargando...",
        //   html: "Por Favor Espere...",
        //   allowEscapeKey: false,
        //   allowOutsideClick: false,
        //   willOpen: () => {
        //     this.$swal.showLoading();
            
        //   },
        // });
        this.$loading.show({ background: 'black', color: '#00FF00' });
        
        let formData = new FormData();
        formData.append("files", this.files);        
        const datospermitidos = {"Content-Type": "multipart/form-data",};
        axios.post("https://ibingcode.com/public/cargarlista",formData,datospermitidos)
          .then((respuesta) => {
            var msg = JSON.parse(respuesta.data);
          
            if(msg.CODIGO == 0){
                   this.$swal.fire({
                     title:"exito",
                     text:msg.MENSAJE,
                     allowOutsideClick: false,
                showConfirmButton:false,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:msg.MENSAJE,
                     icon:"error"})
                 }
          });
      }
    },
    f_generardocumento(idproyecto,tipo_reporte){
     
      let datos={             
             id_proyecto:idproyecto,
             tipo_reporte:tipo_reporte
            };

            if(datos.tipo_reporte==1){
          this.title1="Actas de inspeccion Actualizadas";
        }else if(datos.tipo_reporte==2){
          this.title1="Notificaciones Personales Actualizadas";

        }
        else if(datos.tipo_reporte==3){
          this.title1="Notificaciones de Transito Actualizadas";

        }else if(datos.tipo_reporte==4){
          this.title1="Resoluciones de Abandono Actualizadas";

        }
      axios.post("https://ibingcode.com/public/generardocumento",datos).then((respuesta) => {
        
        
        var msg = JSON.parse(respuesta.data);
        
        if(msg.CODIGO == 0){
                   this.$swal.fire({
                     title:this.title1+", las rutas de descargas ya estan creadas",
                     text:msg.MENSAJE,
                     timer: 3000,
                     icon:"info"})
                 } else {
                   this.$swal.fire({
                     title:this.title1+" y rutas de descargas generadas correctamente",
                     text:msg.MENSAJE,
                     timer: 3000,
                     icon:"succes"})
                 }
   
  });

  
      },
      f_descarga_soporte(id_proyecto,codigo_busqueda){   
      
      window.open('https://ibingcode.com/descarga_soporte.php?id_proyecto='+id_proyecto+'&codigo_busqueda='+codigo_busqueda,'_blank');

   }
    }
  }
</script>
<style scoped>

.swal2-styled.swal2-confirm {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #d69430;
    color: #ffffff;
    font-size: 1.0625em;
}
.swal2-styled.swal2-confirm  {
    border-left-color: #5D5D5D !important;
    border-right-color: #5D5D5D !important;
}
</style>