<template>
    <v-app>
     <NavBar/>
  <v-main style="background: hwb(228 91% 7%);">
        <router-view/>
      </v-main>
      <FooTer/>
    </v-app>
  </template>
  
  <script>
  import NavBar from './includes/NavBar.vue'
  import FooTer from './includes/FooTer.vue'
  export default {
    name: 'App',
    components:{
      NavBar,
      FooTer
    },
    data(){
      return{
      
      }
    },
  created(){
    
  }
  
  };
  </script>