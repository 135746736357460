<template>
    <v-app>
    <h1>
        CARGA LISTA
      </h1>
    <v-file-input
      v-model="files"
      placeholder="Upload your documents"
      label="File input"
      multiple
      prepend-icon="mdi-paperclip"
    >
      <template v-slot:selection="{ fileNames }">
        <template v-for="fileName in fileNames" >
          <v-chip
          :key="fileName"
            size="small"
            label
            color="primary"
            class="me-2"
          >
            {{ fileName }}
          </v-chip>
        </template>
      </template>
    </v-file-input>
</v-app> 
  </template>
  <script>
  export default {
    // data: () => ({
    //   
    // }),
    data() {
      return {
        fileName: "",
        files: [],
           
        
      };
    },
  }
</script>