<template>
 <div class="text-center" >
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      
      
    >
      <template v-slot:activator="{ on, attrs }">
        {{username.toUpperCase()}}
        <v-icon v-bind="attrs" v-on="on" size="36">mdi-account-multiple</v-icon>
      </template>

      <v-card >
        <v-list >
          <v-list-item>
            <v-list-item-avatar>
              <img
                src="https://cdn.vuetifyjs.com/images/john.jpg"
                alt="John"
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{username.toUpperCase()}}</v-list-item-title>
              
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider ></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="cerrarsession()"
          >
           Cerrar Sesion
          </v-btn>
          <!-- <v-btn
            color="danger"
            text
            @click="menu = false"
          >
           Editar Perfil
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
 data: () => ({
      fav: true,
      menu: false,
      message: false,
      hints: true,
      username:''
    }),
    created(){
      this.username=localStorage.getItem('nombre');
    },
    methods:{
cerrarsession(){
  localStorage.removeItem('nombre');
    localStorage.removeItem('token');
    this.$router.push("/");
    location.reload(true);
}
  },
}
</script>

<style>

</style>